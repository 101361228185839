@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

@layer components {

    body {
        @apply text-gray-700 tabular-nums antialiased
    }

    body.collapsed .sidebar-container {
        @apply w-[60px]
    }

    body.collapsed .sidebar__profile {
        @apply justify-center
    }

    body.collapsed .logo__wrapper {
        @apply pl-0
    }

    body.collapsed .hide {
        @apply opacity-0 absolute hidden pointer-events-none transition-all duration-75 ease-in-out
    }

    body.collapsed .sidebar-top {
        @apply flex flex-col p-0 justify-center
    }

    body.collapsed .sidebar-links li a {
        @apply items-center justify-center
    }

    body.collapsed .expand-btn {
        @apply mt-4
    }

    body.collapsed .sidebar-tooltip .tooltip-content {
        @apply hover:visible
    }

    /* sidebar navigation styles */
    .sidebar-container {
        @apply sticky top-0 left-0 h-screen bg-dark-blue w-72 overflow-hidden py-4 px-2 text-white flex flex-col transition-all duration-500 ease-in-out
    }

    .sidebar-top {
        @apply relative flex items-center justify-between flex-row mb-4
    }

    .logo__wrapper {
        @apply pl-2 flex justify-start items-center gap-2
    }

    .expand-btn {
        @apply relative grid place-items-center rounded-md cursor-pointer transition-all duration-150 ease-in-out p-2 rounded-md bg-slate-50/0 hover:bg-slate-50/10
    }

    .sidebar-links li {
        @apply relative
    }

    .sidebar-links li a {
        @apply my-1.5 py-2 px-2 text-white text-xs flex items-center h-10 transition-all duration-150 ease-in-out bg-light-blue/[.5] rounded-md gap-3 hover:text-gold-500 font-medium
    }

    .sidebar-bottom {
        @apply flex justify-center flex-col mt-auto
    }

    .divider {
        @apply w-full block h-px bg-slate-400
    }

    .sidebar-bottom ul {
        @apply my-1.5
    }

    .sidebar__profile {
        @apply flex items-center justify-between pl-1 gap-4 flex-row mt-3
    }

    .avatar__wrapper {
        @apply relative flex
    }

    .avatar {
        @apply block w-8 h-8 cursor-pointer rounded-full hover:scale-110 transition-all duration-150 ease-in-out object-cover 
    }

    .avatar__name {
        @apply flex flex-col gap-0.5
    }

    .user-name {
        @apply text-left text-xs font-medium
    }

    .email {
        @apply text-xs
    }

    .online__status {
        @apply absolute w-3 h-3 rounded-full bg-emerald-500 bottom-0 right-0
    }

    .sidebar-tooltip {
        @apply relative
    }

    .sidebar-tooltip .tooltip-content {
        @apply invisible hover:visible bg-dark-blue text-white text-center rounded-md px-4 py-2 absolute z-[1000] left-[75px]
    }

    .mobile-menu {
        @apply bg-white border-r border-gray-100 h-screen opacity-100 flex flex-col p-3 left-0 fixed shadow-md w-80 z-10 animate-openMenu
    }

    .hide-mobile-menu {
        @apply animate-closeMenu
    }
    /* sidebar navigation styles */

    .section-container {
        @apply max-w-7xl mx-auto py-6 px-4 sm:px-4 md:px-0
    }

    .section-container-wide {
        @apply max-w-full mx-auto 
    }

    .form-label {
        @apply block text-sm font-medium leading-6 text-gray-700
    }
  
    .form-input {
        @apply mt-2 appearance-none text-slate-900 bg-white rounded-md block w-full px-3 h-10 shadow-sm sm:text-sm placeholder:text-slate-400 border border-gray-200
    }

    .form-textarea {
        @apply p-2 mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm
    }

    .form-button {
        @apply inline-flex justify-center rounded-lg text-sm font-semibold py-2.5 px-4 bg-light-blue text-white hover:bg-dark-blue w-full
    }

    .alert-button {
        @apply inline-flex justify-center rounded-lg text-sm font-semibold py-2.5 px-4 bg-transparent text-white hover:shadow-md border border-white w-full
    }

    .form-button-default {
        @apply inline-flex justify-center rounded-lg text-sm font-semibold py-2.5 px-4 bg-gray-100 text-gray-700 hover:bg-gray-200 w-full
    }

    .default-button {
        @apply py-2.5 px-4 bg-dark-blue hover:shadow-sm rounded-lg text-sm font-semibold text-white
    }

    .cancel-button {
        @apply py-2.5 px-4 bg-gray-100 hover:bg-gray-200 rounded-lg text-sm font-semibold text-gray-700
    }

    .application-content {
        @apply w-full py-3 px-6 border rounded-md shadow-sm
    }

    .application-bubble {
        @apply bg-lighter-blue text-sm text-gray-700 flex flex-col items-center justify-center text-center space-y-4 p-2 rounded-md
    }

    .side-by-side-card {
        @apply border border-gray-200 w-full py-3 px-4 rounded-md flex flex-col space-y-2 h-auto hover:shadow-sm ease-in-out duration-150
    }

    .card {
        @apply border border-gray-200 w-full py-4 px-6 rounded-md h-auto
    }

    .danger-button {
        @apply inline-flex justify-center rounded-lg text-sm font-semibold py-2.5 px-4 bg-red-500 text-white hover:bg-red-600 w-auto
    }

    .green-button {
        @apply inline-flex justify-center rounded-lg text-sm font-semibold py-2.5 px-4 bg-emerald-500 text-white hover:bg-emerald-600 w-auto
    }

    .button-groups {
        @apply inline-flex justify-center items-center border border-gray-200 rounded-md overflow-hidden
    }

    .action-buttons {
        @apply justify-center items-center px-4 py-2 text-gray-700 hover:bg-gray-50 hover:text-gray-600 cursor-pointer
    }

    .disabled-button {
        @apply cursor-not-allowed
    }

    /* .verify-action-button {
        @apply hover:bg-emerald-500 hover:text-white transition
    }

    .ban-action-button {
        @apply hover:bg-red-400 hover:text-white transition
    }

    .suspend-action-button {
        @apply hover:bg-yellow-500 hover:text-white transition
    } */

    .pinboxInput {
        @apply w-12 h-12 border border-2  text-center text-2xl mr-3 rounded-md
    }

    .main-container {
        @apply relative min-h-screen md:flex md:flex-row
    }

    .main-content {
        @apply py-4 px-0 md:px-8 md:py-6 space-y-6 md:flex-grow relative
    }

    .shimmer {
        @apply bg-gray-200 animate-pulse;
        background-image: linear-gradient(
          90deg,
          rgba(245, 245, 245, 1) 25%,
          rgba(229, 229, 229, 1) 50%,
          rgba(245, 245, 245, 1) 75%
        );
        background-size: 200% 100%;
    }

}